// For any new style, please, use this file.
footer {
  .footer_info {
    .made-with-love {
      img {
        vertical-align: middle;
        display: inline-block;
      }

      a {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 767px) {
  .product-review_rating {
    display: flex;
    justify-content: center;
  }

  .footer .avards {
    flex-direction: column;
  }
}
